import React, { useEffect, useState } from "react"
import { Button, DatePicker, Input, Pagination, Select, Table } from "antd"
import { toCurrency } from "../../utils/String.js"
import SearchIcon from "../../assets/icons/SearchIcon.js"
import { useQuery } from "react-query"
import { NavLink, useSearchParams } from "react-router-dom"
import dayjs from "dayjs"
import { getHeaderEvaluation, getListEvaluation } from '../../api/Evaluation.js'
import { getListDrOperator, getListProcedure } from '../../api/MasterData.js'
import { getListUser } from '../../api/UserManagement.js'

const { RangePicker } = DatePicker

export default function EvaluationList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const sortField = searchParams.get("sortField") || "";
    const sortOrder = searchParams.get("sortOrder") || "";
    const search = searchParams.get("search") || "";
    const doctor_id = searchParams.get("doctor_id") || "";
    const pic_id = searchParams.get("pic_id") || "";
    const procedure_id = searchParams.get("procedure_id") || "";
    const invoice_start_date = searchParams.get("invoice_start_date") || "";
    const invoice_end_date = searchParams.get("invoice_end_date") || "";
    const [serviceClassMeta, setServiceClassMeta] = useState({})

    const [masterDataOption, setMasterDataOption] = useState({
        namaProsedur: [],
        namaDrOperator: [],
        picList: [],
    });

    const { data: dataHeadEvaluation } = useQuery(
        ['getHeaderEvaluation',{
            doctor_id, 
            pic_id, 
            procedure_id,
            invoice_start_date, 
            invoice_end_date, 
            search
        }],
        () => getHeaderEvaluation({
                search,
                doctor_id, 
                pic_id, 
                procedure_id,
                invoice_start_date, 
                invoice_end_date, 
            }),
        {
            enabled: true,
        }
    );

    useQuery({
        queryKey: ["listNamaProsedur"],
        queryFn: () => getListProcedure(),
        onSuccess: (data) => {
            setMasterDataOption((prevMasterOption) => ({
                ...prevMasterOption,
                namaProsedur: data?.data
            }))
            // console.log('operasi', data);
        },
        onError: ({ response }) => {
            console.log("error", response)
        }
    });

    useQuery({
        queryKey: ["listDrOperator"],
        queryFn: () => getListDrOperator(),
        onSuccess: (data) => {
            setMasterDataOption((prevMasterOption) => ({
                ...prevMasterOption,
                namaDrOperator: data?.data
            }))
        },
        onError: ({ response }) => {
            console.log(response)
        }
    });
    
    useQuery({
        queryKey: ["listUser"],
        queryFn: () => getListUser(),
        onSuccess: (data) => {
            setMasterDataOption((prevMasterOption) => ({
                ...prevMasterOption,
                picList: data?.data
            }))
        },
        onError: ({ response }) => {
            console.log(response)
        }
    });

    const { data, isFetching, isLoading } = useQuery({
        queryKey: ["serviceClasses", { 
            page, 
            pageSize, 
            sortField, 
            sortOrder, 
            doctor_id, 
            pic_id, 
            procedure_id,
            invoice_start_date, 
            invoice_end_date, 
            search }],
        queryFn: () =>
            getListEvaluation({
                search,
                doctor_id, 
                pic_id, 
                procedure_id,
                invoice_start_date, 
                invoice_end_date, 
                page,
                limit: pageSize,
                sort: sortOrder && sortOrder === "descend" ? `-${sortField}` : sortField
            }),
        onSuccess: (data) => {
            setServiceClassMeta(data.meta)
        },
        onError: ({ response }) => {
            console.log(response)
        }
    });

    const columns = [
        {
            title: "Admission No",
            dataIndex: "invoice_no",
            key: "invoice_no",
            sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
            showSorterTooltip: false
        },
        {
            title: "Admission Date",
            dataIndex: "invoice_date",
            key: "invoice_date",
            sorter: (a, b) => a.invoice_date.length - b.invoice_date.length,
            showSorterTooltip: false
        },
        {
            title: "Admission Date",
            dataIndex: "admission_date",
            key: "admission_date",
            sorter: (a, b) => a.admission_date.length - b.admission_date.length,
            showSorterTooltip: false
        },
        {
            title: "Pasien",
            dataIndex: "pasien",
            key: "pasien",
            sorter: (a, b) => a.pasien.length - b.pasien.length,
            showSorterTooltip: false
        },
        {
            title: "Doctor",
            dataIndex: "doctor",
            key: "doctor",
            sorter: (a, b) => a.doctor.length - b.doctor.length,
            showSorterTooltip: false
        },
        {
            title: "PIC",
            dataIndex: "pic",
            key: "pic",
            sorter: (a, b) => a.pic.length - b.pic.length,
            showSorterTooltip: false
        },
        {
            title: "EMR",
            dataIndex: "emr",
            key: "emr",
            sorter: (a, b) => a.emr.length - b.emr.length,
            showSorterTooltip: false
        },
        {
            title: "Nama Prosedur",
            dataIndex: "procedures",
            key: "procedures",
            sorter: (a, b) => a.procedures.length - b.procedures.length,
            showSorterTooltip: false,
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <NavLink to={`/evaluation/${record?.id}`} className="sl-flex sl-items-center sl-gap-2">
                    <Button className="sl-font-semibold">Evaluation</Button>
                </NavLink>
            )
        }
    ];

    const dataEvalution = data?.data?.map((item, index) => (
        {
            key : index,
            id : item?.id,
            invoice_no : item?.invoice_no,
            invoice_date : dayjs(item?.invoice_date).format("DD MMM YYYY, HH:mm"),
            admission_date : dayjs(item?.admission_date).format("DD MMM YYYY, HH:mm"),
            pasien : item?.patient_name,
            doctor : item?.doctor_name,
            pic : item?.created_by_name,
            emr : item?.medical_record_no,
            procedures : item?.procedures,
        }
    ));
    
    const updateSearchParams = (params) => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams)
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    newParams.set(key, value)
                } else {
                    newParams.delete(key)
                }
            })

            if (params.sortField && !params.sortOrder) {
                newParams.delete("sortField")
            }
            return newParams
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        updateSearchParams({
            sortField: sorter.field,
            sortOrder: sorter.order
        })
    }

    const handlePaginationChange = (page, pageSize) => {
        updateSearchParams({ page, pageSize })
    }

    const handlePageSizeChange = (pageSize) => {
        updateSearchParams({ page: 1, pageSize })
    }

    const handleSearchChange = (e) => {
        updateSearchParams({ search: e.target.value })
    }

    const disabledDate = (current) => {
        // Can not select days after today
        return current && current >= dayjs().endOf("day")
    }

    const handleDateChange = (date, dateStrings) => {
        updateSearchParams({ 
            invoice_start_date: dateStrings[0],
            invoice_end_date: dateStrings[1]
        });
    };
    
    const handleSelectDoctor = (value) => {
        updateSearchParams({ 
            doctor_id : value
        });
    };
    
    const handleSelectPic = (value) => {
        updateSearchParams({ 
            pic_id : value
        });
    };
    
    const handleSelectProcedure = (value) => {
        updateSearchParams({ 
            procedure_id : value
        });
    };

    useEffect(() => {
        if (!searchParams.get("page")) {
            setSearchParams({ page: 1, pageSize: 10 })
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        updateSearchParams({ 
            start_date: "",
            end_date: ""
        });
    }, [])

    const getDefaultValueRangePicker = () => {
        let defaultValue = [];
       
        if (dayjs(invoice_start_date).isValid() && dayjs(invoice_end_date).isValid()) {
            defaultValue = [dayjs(invoice_start_date), dayjs(invoice_end_date)]
        }

        return defaultValue
    }

    return (
        <div>
            <div className="sl-flex sl-items-center sl-gap-4 sl-mb-[32px]">
                <div className="sl-text-2xl sl-font-semibold">Evaluation</div>
            </div>

            <div className="sl-flex sl-flex-col sl-gap-2">
                <div className="sl-flex sl-justify-between sl-gap-4">
                    <div className="sl-flex sl-gap-2">
                        <RangePicker
                            // className="custom-range-picker hide-enddate"
                            className="sl-h-8"
                            format="YYYY-MM-DD"
                            separator="-"
                            disabledDate={disabledDate}
                            onChange={handleDateChange}
                            defaultValue={getDefaultValueRangePicker()}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[150px]"
                            showSearch
                            placeholder="Dokter"
                            value={doctor_id ? parseInt(doctor_id) : null}
                            onChange={(value) => {
                                handleSelectDoctor(value);
                            }}
                            filterOption={(input, option) =>
                                option?.searchLabel?.toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                { value: "", label: (<span className="sl-text-text-secondary">Semua</span>), searchLabel: "", },
                                ...(Array.isArray(masterDataOption?.namaDrOperator) ? masterDataOption?.namaDrOperator.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                    searchLabel: item.name,
                                })) : []) 
                            ]}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[150px]"
                            showSearch
                            placeholder="PIC"
                            value={pic_id ? parseInt(pic_id) : null}
                            onChange={(value) => {
                                handleSelectPic(value);
                            }}
                            filterOption={(input, option) =>
                                option?.searchLabel?.toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                { value: "", label: (<span className="sl-text-text-secondary">Semua</span>), searchLabel: "", },
                                ...(Array.isArray(masterDataOption?.picList) ? masterDataOption?.picList.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                    searchLabel: item.name,
                                })) : []) 
                            ]}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[250px]"
                            showSearch
                            placeholder="Procedure"
                            value={procedure_id ? parseInt(procedure_id) : null}
                            onChange={(value) => {
                                handleSelectProcedure(value);
                            }}
                            filterOption={(input, option) =>
                                option?.searchLabel?.toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                { value: "", label: (<span className="sl-text-text-secondary">Semua</span>), searchLabel: "", },
                                ...(Array.isArray(masterDataOption?.namaProsedur) ? masterDataOption?.namaProsedur.map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                    searchLabel: item.name,
                                })) : []) 
                            ]}
                        />
                    </div>
                    <Input
                        value={search}
                        placeholder="Search"
                        suffix={<SearchIcon />}
                        className="sl-w-[200px] sl-mb-[16px]"
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="sl-flex sl-justify-between sl-gap-4 sl-mb-6">
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Total Bill Estimation</p>
                        <p className="sl-text-3xl sl-font-semibold">
                            {toCurrency(dataHeadEvaluation?.data?.total_price_estimation)}
                        </p>
                    </div>
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Total Actual Invoice</p>
                        <p className="sl-text-3xl sl-font-semibold">
                            {toCurrency(dataHeadEvaluation?.data?.total_invoice)}
                        </p>
                    </div>
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Deviation</p>
                        <p className={`sl-text-3xl sl-font-semibold ${dataHeadEvaluation?.data?.total_deviation >= 0 ? "sl-text-text-success" : "sl-text-[#B91C1C]"}`}>
                            {toCurrency(dataHeadEvaluation?.data?.total_deviation)} ({dataHeadEvaluation?.data?.deviation_percentage}%)
                        </p>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={dataEvalution}
                    pagination={false}
                    loading={isLoading || isFetching}
                    onChange={handleTableChange}
                    footer={() => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <div>
                                Display{" "}
                                <Select
                                    className="sl-w-[80px]"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    options={[
                                        { value: 10, label: "10" },
                                        { value: 20, label: "20" },
                                        { value: 50, label: "50" },
                                        { value: 100, label: "100" }
                                    ]}
                                />{" "}
                                of {serviceClassMeta.total} data.
                            </div>
                            <Pagination
                                current={page}
                                pageSize={pageSize}
                                total={serviceClassMeta.total}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    )
}
