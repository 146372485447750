import { axiosGlobal } from "../utils/AxiosConfig.js"

export const getServiceClass = async (params) => {
    const { data: response } = await axiosGlobal.get("service-classes", {
        params: params
    })

    return response
}

export const getListOperasi = async () => {
    const { data: response } = await axiosGlobal.get("surgeries")

    return response
}

export const getListDrOperator = async (params) => {
    const { data: response } = await axiosGlobal.get("doctors", {
        params: params
    })

    return response
}

export const getListOtherService = async (params) => {
    const { data: response } = await axiosGlobal.get("master/other-items", {
        params: params
    })

    return response
}

export const getListPerincianAlat = async (params) => {
    const { data: response } = await axiosGlobal.get("master/medical-equipment-rentals", {
        params: params
    })

    return response
}

export const getAdmissionType = async (params) => {
    const { data: response } = await axiosGlobal.get("master/referral-admission-types", {
        params: params
    })

    return response
}

export const getDiseasClasification = async (params) => {
    const { data: response } = await axiosGlobal.get("master/diseases", {
        params: params
    })

    return response
}

export const uploadFiles = async (payload) => {
    try {
        const URL = `files`
        const option = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        const response = await axiosGlobal.post(URL, payload, option)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getListOrganization = async () => {
    const { data: response } = await axiosGlobal.get("master/organizations")
    return response
}
export const getListDisease = async (params) => {
    const { data: response } = await axiosGlobal.get("master/diseases?limit=400", { params })
    return response
}
export const getListProcedure = async (params) => {
    const { data: response } = await axiosGlobal.get("master/procedures", { params })
    return response
}
export const getListAdmissionType = async () => {
    const { data: response } = await axiosGlobal.get("master/referral-admission-types")
    return response
}
export const getListDrAnestesi = async (params) => {
    const { data: response } = await axiosGlobal.get("doctors?is_anesthesiologist=true",{ params })
    return response
}
export const getListService = async (params) => {
    const { data: response } = await axiosGlobal.get("items?category=OTHER_ITEMS", {
        params: params
    })
    return response
}
export const getListServiceClass = async () => {
    const { data: response } = await axiosGlobal.get("service-classes")
    return response
}
export const getListGender = async () => {
    const { data: response } = await axiosGlobal.get("master/genders")
    return response
}
export const getListBedType = async () => {
    const { data: response } = await axiosGlobal.get("master/bed-types")
    return response
}
export const getListNatureOperation = async () => {
    const { data: response } = await axiosGlobal.get("master/nature-operations")
    return response
}
export const getListTenant = async () => {
    const { data: response } = await axiosGlobal.get("master/tenants")
    return response
}
export const getListInsurance = async (params) => {
    const { data: response } = await axiosGlobal.get("master/insurances", { params })
    return response
}
